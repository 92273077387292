<template>
  <div style="margin-top: 120px;">
    <search-nav :active="6"></search-nav>
    <!-- <el-affix :offset="120"> -->
      <el-row>
        <div class="container">
          <!--输入框-->
          <el-row style="margin-bottom: 30px">
            <el-col :span="13">
              <div>
                <el-autocomplete
                  style="width: 100%"
                  clearable
                  class="inline-input"
                  :fetch-suggestions="querySearch"
                  v-model="conditions.keyword"
                  placeholder="请输入知识产权关键词"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                  size="medium"
                  input-style="border-bottom-right-radius:0px;border-top-right-radius:0px;height:50px"
                >
                </el-autocomplete>
              </div>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" style="background: #FC7A1E;border-color: #FC7A1E;" class="search-btn-search" @click="handleSearchList" size="medium">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </el-row>
    <!-- </el-affix> -->
    <el-row>
      <div class="container">
        <!--筛选条件-->
        <div style="margin-bottom: 30px" class="condition-content">
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">筛选条件</span>
            <link-to-advanced></link-to-advanced>
          </div>
          <div style="padding: 10px 0 20px 0;">
          <el-row v-if="tags.length > 0" class="dis-cen-cen">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #5D6FE9;height: 40px;line-height: 40px;">已选条件：</div></el-col>
            <el-col :span="20">
              <div class="grid-content bg-purple dis-sta-cen">
                <el-tag style="margin-right: 10px" v-for="(tag, index) in tags" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                  {{tag.name}}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="2">
              <div @click="closeAllTag" class="grid-content bg-purple font-choose-text">清除全部</div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.provinceModel.name" class="dis-cen-sta">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;" >企业地区：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <arrangement-radio :valueArr="arrangementRadioProvinceValueArr" :defaultShowNum="arrangementRadioProvinceDefaultShowNum" @handleChange="arrangementRadioProvinceChange"></arrangement-radio>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.cityModel.name && conditions.provinceModel.name && conditions.provinceModel.name != '北京市' && conditions.provinceModel.name != '天津市' && conditions.provinceModel.name != '上海市' && conditions.provinceModel.name != '重庆市'" class="dis-cen-sta">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;">城市：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <arrangement-radio :valueArr="arrangementRadioCityValueArr" :defaultShowNum="arrangementRadioCityDefaultShowNum" @handleChange="arrangementRadioCityChange"></arrangement-radio>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.intellectualPropertyType" class="dis-cen-cen">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">知识产权分类：</div></el-col>
            <el-col :span="22">
              <el-row>
                <el-col :span="2">
                  <el-button type="text" style="float: left;" @click="intellectualPropertyTypeClick('1')"><p class="hover-class">商标信息</p></el-button>
                </el-col>
                <el-col :span="2" style="float: left; margin: 0px 0px 0px 10px;">
                  <el-button type="text" style="float: left;" @click="intellectualPropertyTypeClick('2')"><p class="hover-class" >专利信息</p></el-button>
                </el-col>
                <el-col :span="2" style="float: left; margin: 0px 0px 0px 10px;">
                  <el-button type="text" style="float: left;" @click="intellectualPropertyTypeClick('3')"><p class="hover-class" >作品著作权</p></el-button>
                </el-col>
                <el-col :span="2" style="float: left; margin: 0px 0px 0px 10px;">
                  <el-button type="text" style="float: left;" @click="intellectualPropertyTypeClick('4')"><p class="hover-class">软件著作权</p></el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.companyKeyword" class="dis-cen-cen" style="margin-top: 10px;">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">企业名称：</div></el-col>
            <el-col :span="22">
              <el-row class="dis-sta-cen">
                <el-col :span="13">
                  <div>
                    <el-input  v-model="companyKeyword" placeholder="请输入企业名称关键词" size="medium" clearable></el-input>
                  </div>
                </el-col>
                <el-col :span="4" style="float: left; margin: 0px 0px 0px 10px;">
                  <el-button type="" style="float: left"  size="medium" @click="companyKeywordClick">确定</el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="dis-cen-cen" style="margin-top: 15px;">
            <el-col :span="2">
              <div class="grid-content bg-purple" >
                <el-button type="primary" @click="queryList" size="medium">查询</el-button>
              </div>
            </el-col>
          </el-row>
          </div>
        </div>
        <!--内容卡片 柏成大佬-->
        <el-row style="margin-top: 30px;margin-bottom: 30px">
          <el-col :span="24">
            <div class="condition-content">
              <div class="dis-bet-cen condition-title">
                <span class="screening-conditions-title">共查询到 <span class="font-color-red">{{ page.total }}</span> 条符合条件的数据</span>
                <span class="advanced-query-title">
              </span>
              </div>
              <!--专利信息-->
              <div class="condition-content-list" v-if="queryConditions.intellectualPropertyType == '专利信息'">
                <block v-for="(item, index) of dataList" :key="index">
                  <el-row class="list-padding list-hover" @click="companyAchievementNameClick(item, 'zl')">
                    <el-col :span="24">
                      <div class="condition-content-list-name dis-bet-cen">
                        <div class="dis-sta-cen"><span v-html="item.title"></span></div>
                        <span v-if="item.isfocus == '4'">
                          <el-tooltip class="item" effect="dark" content="用户提供了完整的资料数据，但无法在公开网站上核验" placement="bottom-end">
                            <span  class="focus-enterprises-tag-yellow">未核验</span>
                          </el-tooltip>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="5">法律状态：<span class="font-color-gray">{{item.legalstatusdesc || '-'}}</span></el-col>
                        <el-col :span="5">申请日期：<span class="font-color-gray">{{item.applicationdate || '-'}}</span></el-col>
                        <el-col :span="5">公布日期：<span class="font-color-gray">{{item.publicationdate || '-'}}</span></el-col>
                        <el-col :span="5">申请号：<span class="font-color-gray">{{item.applicationnumber || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="10">企业名称：<span class="font-color-gray">{{item.companyName || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                </block>
                <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
              </div>
              <!--作品著作权-->
              <div class="condition-content-list" v-if="queryConditions.intellectualPropertyType == '作品著作权'">
                <block v-for="(item, index) of dataList" :key="index">
                  <el-row class="list-padding list-hover">
                    <el-col :span="24">
                      <div class="condition-content-list-name dis-bet-cen">
                        <div class="dis-sta-cen"><span v-html="item.name"></span></div>
                        <span v-if="item.isfocus == '4'">
                      <el-tooltip class="item" effect="dark" content="用户提供了完整的资料数据，但无法在公开网站上核验" placement="bottom-end">
                        <span  class="focus-enterprises-tag-yellow">未核验</span>
                      </el-tooltip>
                    </span>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="5">首次发表日期：<span class="font-color-gray">{{item.publishdate || '-'}}</span></el-col>
                        <el-col :span="5">创作完成日期：<span class="font-color-gray">{{item.finishdate || '-'}}</span></el-col>
                        <el-col :span="5">登记类别：<span class="font-color-gray">{{item.category || '-'}}</span></el-col>
                        <el-col :span="5">登记日期：<span class="font-color-gray">{{item.registerdate || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="10">企业名称：<span style="cursor:pointer;" @click="companyNameClick(item, index)" class="font-color-gray">{{item.companyName || '-'}}</span></el-col>
                        <el-col :span="10">登记号：<span class="font-color-gray">{{item.registerno || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                </block>
                <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
              </div>
              <!--软件著作权-->
              <div class="condition-content-list" v-if="queryConditions.intellectualPropertyType == '软件著作权'">
                <block v-for="(item, index) of dataList" :key="index">
                  <el-row class="list-padding list-hover">
                    <el-col :span="24">
                      <div class="condition-content-list-name dis-bet-cen">
                        <div class="dis-sta-cen"><span v-html="item.name"></span></div>
                        <span v-if="item.isfocus == '4'">
                      <el-tooltip class="item" effect="dark" content="用户提供了完整的资料数据，但无法在公开网站上核验" placement="bottom-end">
                        <span  class="focus-enterprises-tag-yellow">未核验</span>
                      </el-tooltip>
                    </span>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="5">软件简称：<span class="font-color-gray">{{item.shortname || '-'}}</span></el-col>
                        <el-col :span="5">版本号：<span class="font-color-gray">{{item.versionno || '-'}}</span></el-col>
                        <el-col :span="5">发布日期：<span class="font-color-gray">{{item.publishdate || '-'}}</span></el-col>
                        <el-col :span="5">分类号：<span class="font-color-gray">{{item.category || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="10">企业名称：<span style="cursor:pointer;" @click="companyNameClick(item, index)" class="font-color-gray">{{item.companyName || '-'}}</span></el-col>
                        <el-col :span="5">登记号：<span class="font-color-gray">{{item.registerno || '-'}}</span></el-col>
                        <el-col :span="5">登记批准日期：<span class="font-color-gray">{{item.registeraperdate || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                </block>
                <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
              </div>
              <!--商标信息-->
              <div class="condition-content-list" v-if="queryConditions.intellectualPropertyType == '商标信息'">
                <block v-for="(item, index) of dataList" :key="index">
                  <el-row class="list-padding list-hover dis-cen-cen" :gutter="20" @click.stop="companyAchievementNameClick(item, 'sb')">
                    <el-col :span="4">
                      <el-image style="border: 1px solid #E6E6E6;height: 160px;width:160px;" :src="item.imageurl" fit="contain">
                        <template #error>
                          <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                            <div>暂无图片</div>
                          </div>
                        </template>
                      </el-image>
                    </el-col>
                    <el-col :span="19" class="dis-cen-cen" >
                      <el-row :gutter="20">
                        <el-col :span="24" class="condition-content-list-name"><span v-html="item.name"></span></el-col>
                      </el-row>
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col style="margin-bottom: 10px;" :span="10">注册号：<span class="font-color-gray">{{item.regno || '-'}}</span></el-col>
                        <el-col style="margin-bottom: 10px;" :span="10">申请日期：<span class="font-color-gray">{{item.appdate || '-'}}</span></el-col>
                        <el-col style="margin-bottom: 10px;" :span="10">国际分类：<span class="font-color-gray">{{item.intcls || '-'}}{{item.intclsdesc || '-'}}</span></el-col>
                        <el-col style="margin-bottom: 10px;" :span="10">注册公告日期：<span class="font-color-gray">{{item.regdate || '-'}}</span></el-col>
                        <el-col style="margin-bottom: 10px;" :span="10">企业名称：<span class="font-color-gray" style="cursor:pointer;" @click.capture.stop="companyNameClick(item, index)">{{item.companyName || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                </block>
                <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--分页组件-->
        <div class="dis-end-sta" style="margin-bottom: 30px">
          <el-pagination
            :hide-on-single-page="true"
            :pager-count="page.pagerCount"
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            v-model:currentPage="page.pageNum"
            :total="page.total">
          </el-pagination>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import ArrangementRadio from '@/components/query-conditions/arrangement-radio' // 省市的平铺单选
import { ElMessage } from 'element-plus'
import SearchNav from '@/components/search-nav/search-nav'
import LinkToAdvanced from '@/components/link-to-advanced/link-to-advanced'
import provinceData from '@/assets/pca-code.js'
import { forwardBG } from '@/api/forward.js'
// import GaussianBlur from '@/components/gaussian-blur/gaussian-blur'
import { mapGetters } from 'vuex'
export default {
  name: 'company-intellectual-property',
  components: {
    ArrangementRadio,
    SearchNav,
    LinkToAdvanced
    // GaussianBlur
  },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'topNav')
    }
    return {
      loginEmit
    }
  },
  data () {
    return {
      provinceData: provinceData,
      companyKeyword: '', // 企业名称关键字
      conditions: {
        keyword: '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        intellectualPropertyType: '', // 知识产权分类
        companyKeyword: '' // 已经选择的企业名称
      },
      queryConditions: {
        keyword: '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        intellectualPropertyType: '商标信息', // 知识产权分类
        companyKeyword: '' // 已经选择的企业名称
      },
      oldKeyword: '',
      arrangementRadioProvinceValueArr: [], // 平铺单选的组件数据 省
      arrangementRadioProvinceDefaultShowNum: 12, // 平铺单选的默认显示数量 省
      arrangementRadioCityValueArr: [], // 平铺单选的组件数据 市
      arrangementRadioCityDefaultShowNum: 10, // 平铺单选的默认显示数量 市
      tags: [],
      url: require('../../assets/index/g.png'),
      // 数据
      dataList: [],
      page: { // 分页使用
        total: 0, // 总条数
        pagerCount: 7,
        pageNum: 1
      }
    }
  },
  computed: {
    ...mapGetters([
      'idToken'
    ])
  },
  mounted () {
    const vm = this
    // 初始化 省市数据
    vm.initArrangementRadioValueArr()
    vm.handleSearchList()
  },
  methods: {
    // 输入框输入内容触发
    querySearch (queryString, callback) {
      const arr = []
      callback(arr)
    },
    // 输入框选择之后返回的数据
    handleSelect (item) {
      console.log(item)
    },
    initArrangementRadioValueArr () {
      const vm = this
      vm.arrangementRadioProvinceValueArr = vm.provinceData.pcaData
    },
    // 省市组件选择完了的回调 省
    arrangementRadioProvinceChange (item) {
      const vm = this
      vm.arrangementRadioCityValueArr = item.children
      vm.conditions.provinceModel = item
      vm.tags.push({
        name: '企业地区：' + item.name,
        value: 'provinceModel',
        default: {}
      })
    },
    // 省市组件选择完了的回调 市
    arrangementRadioCityChange (item) {
      const vm = this
      vm.conditions.cityModel = item
      vm.tags.push({
        name: '城市：' + item.name,
        value: 'cityModel',
        default: {}
      })
    },
    // 选择知识产权分类触发
    intellectualPropertyTypeClick (obj) {
      const vm = this
      let intellectualPropertyType = ''
      if (obj === '1') intellectualPropertyType = '商标信息'
      if (obj === '2') intellectualPropertyType = '专利信息'
      if (obj === '3') intellectualPropertyType = '作品著作权'
      if (obj === '4') intellectualPropertyType = '软件著作权'
      vm.conditions.intellectualPropertyType = intellectualPropertyType
      vm.tags.push({
        name: '知识产权分类：' + intellectualPropertyType,
        value: 'intellectualPropertyType',
        default: ''
      })
    },
    // 企业名称的确定按钮
    companyKeywordClick () {
      const vm = this
      if (vm.companyKeyword) {
        vm.conditions.companyKeyword = vm.companyKeyword
        vm.companyKeyword = ''
        vm.tags.push({
          name: '企业名称：' + vm.conditions.companyKeyword,
          value: 'companyKeyword',
          default: ''
        })
      } else {
        return ElMessage.warning({
          message: '请先输入企业名称关键字',
          type: 'warning',
          offset: 60
        })
      }
    },
    // 关闭Tag触发
    closeTag (item, index) {
      const vm = this
      vm.tags.splice(index, 1)
      vm.conditions[item.value] = item.default
      if (item.value === 'provinceModel') {
        vm.conditions.cityModel = {}
        for (let i = 0; i < vm.tags.length; i++) {
          if (vm.tags[i].value === 'cityModel') {
            return vm.closeTag(vm.tags[i], i)
          }
        }
      }
    },
    // 清除全部Tag
    closeAllTag () {
      const vm = this
      vm.tags = []
      vm.conditions.provinceModel = {}
      vm.conditions.cityModel = {}
      vm.conditions.intellectualPropertyType = ''
      vm.conditions.companyKeyword = ''
    },
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      document.documentElement.scrollTop = 0
      vm.page.pageNum = cuttentPage
      vm.getList()
    },
    queryList: function () {
      const vm = this
      vm.queryConditions.provinceModel = vm.conditions.provinceModel
      vm.queryConditions.cityModel = vm.conditions.cityModel
      vm.queryConditions.companyKeyword = vm.conditions.companyKeyword
      vm.queryConditions.intellectualPropertyType = vm.conditions.intellectualPropertyType ? vm.conditions.intellectualPropertyType : '商标信息'
      vm.page.pageNum = 1
      vm.getList()
    },
    // 点击查询触发
    handleSearchList: function () {
      const vm = this
      // 如果跟上次查询的不一样就清理下面
      if (vm.oldKeyword !== vm.conditions.keyword) {
        vm.closeAllTag()
        vm.queryConditions.keyword = ''
        vm.queryConditions.provinceModel = {}
        vm.queryConditions.cityModel = {}
        vm.queryConditions.intellectualPropertyType = '商标信息'
        vm.queryConditions.companyKeyword = ''
      }
      vm.oldKeyword = vm.conditions.keyword
      vm.queryConditions.keyword = vm.conditions.keyword
      vm.page.pageNum = 1
      // 去查询
      vm.getList()
    },
    getList: function () {
      const vm = this
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        dateBackgroundUrl: '/esHnowledgeRight/getESHnowledgeRightListByModel',
        dateBackgroundRequestType: 'GET',
        data: {
          hnowledgeRightName: vm.queryConditions.keyword ? vm.queryConditions.keyword.trim() : null,
          hnowledgeRightType: vm.queryConditions.intellectualPropertyType ? vm.queryConditions.intellectualPropertyType : '商标信息',
          companyName: vm.queryConditions.companyKeyword ? vm.queryConditions.companyKeyword.trim() : null,
          companyProvince: vm.queryConditions.provinceModel.name ? vm.queryConditions.provinceModel.name : null,
          companyCity: vm.queryConditions.cityModel.name ? vm.queryConditions.cityModel.name : null,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          const companyIds = []
          if (res.length > 0) {
            res.forEach((item, index) => {
              vm.page.total = item.dataTotal
              let obj = {}
              if (vm.queryConditions.intellectualPropertyType === '专利信息') {
                obj = {
                  id: item.id,
                  companyId: item.companyId,
                  companyName: item.companyName,
                  applicationdate: item.applicationdate === null || item.applicationdate === 'null' || item.applicationdate === '' ? '-' : (item.applicationdate).substring(0, 10),
                  publicationdate: item.publicationdate === null || item.publicationdate === 'null' || item.publicationdate === '' ? '-' : (item.publicationdate).substring(0, 10),
                  title: item.title === null || item.title === 'null' ? '-' : item.title,
                  legalstatusdesc: item.legalstatusdesc === null || item.legalstatusdesc === 'null' || item.legalstatusdesc === '' ? '-' : item.legalstatusdesc,
                  publicationnumber: item.publicationnumber === null || item.publicationnumber === 'null' || item.publicationnumber === '' ? '-' : item.publicationnumber
                }
              } else if (vm.queryConditions.intellectualPropertyType === '商标信息') {
                obj = {
                  id: item.id,
                  imageurl: item.imageurl,
                  companyId: item.companyId,
                  companyName: item.companyName,
                  appdate: item.appdate === null || item.appdate === 'null' || item.appdate === '' ? '-' : (item.appdate).substring(0, 10),
                  name: item.name === null || item.name === 'null' ? '-' : item.name,
                  regno: item.regno === null || item.regno === 'null' || item.regno === '' ? '-' : item.regno,
                  tmType: item.tmType === null || item.tmType === 'null' || item.tmType === '' ? '-' : item.tmType
                }
              } else if (vm.queryConditions.intellectualPropertyType === '作品著作权') {
                obj = {
                  id: item.id,
                  companyId: item.companyId,
                  companyName: item.companyName,
                  registerdate: item.registerdate === null || item.registerdate === 'null' || item.registerdate === '' ? '-' : (item.registerdate).substring(0, 10),
                  name: item.name === null || item.name === 'null' ? '-' : item.name,
                  registerno: item.registerno === null || item.registerno === 'null' || item.registerno === '' ? '-' : item.registerno,
                  category: item.category === null || item.category === 'null' || item.category === '' ? '-' : item.category
                }
              } else if (vm.queryConditions.intellectualPropertyType === '软件著作权') {
                obj = {
                  id: item.id,
                  companyId: item.companyId,
                  companyName: item.companyName,
                  registeraperdate: item.registeraperdate === null || item.registeraperdate === 'null' || item.registeraperdate === '' ? '-' : (item.registeraperdate).substring(0, 10),
                  name: item.name === null || item.name === 'null' ? '-' : item.name,
                  registerno: item.registerno === null || item.registerno === 'null' || item.registerno === '' ? '-' : item.registerno,
                  category: item.category === null || item.category === 'null' || item.category === '' ? '-' : item.category
                }
              }
              companyIds.push(item.id)
              arr.push(obj)
            })
          } else {
            vm.page.total = 0
          }
          loading.close()
          vm.dataList = arr
          // 去查询ES中没有的字段
          if (companyIds.length > 0) {
            let url = ''
            if (vm.queryConditions.intellectualPropertyType === '商标信息') {
              url = '/tm/getTmByIds'
              vm.getListOtherData(companyIds, url)
            } else if (vm.queryConditions.intellectualPropertyType === '专利信息') {
              url = '/patent/getPatentByIds'
              vm.getListOtherData(companyIds, url)
            } else if (vm.queryConditions.intellectualPropertyType === '作品著作权') {
              url = '/copyrightsCopyright/getCopyrightsCopyrightByIds'
              vm.getListOtherData(companyIds, url)
            } else if (vm.queryConditions.intellectualPropertyType === '软件著作权') {
              url = '/copyrightsSoftwarecopyright/getCopyrightsSoftwarecopyrightByIds'
              vm.getListOtherData(companyIds, url)
            }
          }
        }
      }).catch(error => {
        console.log(error)
        loading.close()
      })
    },
    getListOtherData (companyIds, url) {
      const vm = this
      const data = {
        dateBackgroundUrl: url,
        dateBackgroundRequestType: 'get',
        data: {
          ids: companyIds.toString()
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let j = 0; j < vm.dataList.length; j++) {
            for (let i = 0; i < data.length; i++) {
              if (vm.queryConditions.intellectualPropertyType === '商标信息') {
                if (vm.dataList[j].id === data[i].id) {
                  vm.dataList[j].intclsdesc = data[i].intclsdesc === null || data[i].intclsdesc === 'null' || data[i].intclsdesc === '' ? '-' : data[i].intclsdesc
                  vm.dataList[j].intcls = data[i].intcls === null || data[i].intcls === 'null' || data[i].intcls === '' ? '-' : data[i].intcls
                  vm.dataList[j].regdate = data[i].regdate === null || data[i].regdate === 'null' || data[i].regdate === '' ? '-' : data[i].regdate.substring(0, 10)
                }
              } else if (vm.queryConditions.intellectualPropertyType === '作品著作权') {
                if (vm.dataList[j].id === data[i].id) {
                  vm.dataList[j].finishdate = data[i].finishdate ? (data[i].finishdate).substring(0, 10) : '-'
                  vm.dataList[j].publishdate = data[i].publishdate ? (data[i].publishdate).substring(0, 10) : '-'
                }
              } else if (vm.queryConditions.intellectualPropertyType === '软件著作权') {
                if (vm.dataList[j].id === data[i].id) {
                  vm.dataList[j].versionno = data[i].versionno ? data[i].versionno : '-'
                  vm.dataList[j].publishdate = data[i].publishdate ? (data[i].publishdate).substring(0, 10) : '-'
                  vm.dataList[j].shortname = data[i].shortname ? data[i].shortname : '-'
                }
              }
            }
          }
          console.log(vm.dataList)
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 点击了 公司名 跳转到公司详情
    companyNameClick (item, index) {
      const vm = this
      vm.$store.dispatch('setBusId', item.companyId)
      vm.$router.push({ name: 'enterprise-details' })
    },
    // 点击了 公司名 跳转到公司详情
    companyAchievementNameClick (item, index) {
      const vm = this
      sessionStorage.removeItem('company-intellectual-details-item')
      sessionStorage.setItem('company-intellectual-details-item', JSON.stringify(item))
      if (index === 'zl') {
        const routeData = vm.$router.resolve({
          name: 'company-patent-details'
        })
        window.open(routeData.href, '_blank')
      }
      if (index === 'sb') {
        const routeData = vm.$router.resolve({
          name: 'company-tm-details'
        })
        window.open(routeData.href, '_blank')
      }
    }
  }
}
</script>

<style scoped="scoped">
.already-choose{
  margin-bottom: 10px;
  margin-top: 10px
}
.input-search {
  margin-top: 120px;
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background-color: #5D6FE9;
  border: none;
  color: #fff;
  width: 70px;
}
.conditions-font-title {
  font-size: 14px;
  color: #999;
  display: block;
  height: 40px;
  line-height: 40px;
}
.focus-enterprises-tag {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #5D6FE9;
  color: #5D6FE9;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-gray {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #E6E6E6;
  color: #666;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-yellow {
  color: #EA9920;
  padding: 0 5px;
  border: 1px solid #EA9920;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}
.hover-class {
  font-size: 14px;
  color: #666666;
}
.hover-class:hover {
  color: rgba(93, 111, 233, 1);
}
.list-padding {
  margin: 0 !important;
}
.list-hover:hover{
  background-color: #F3F9FD;
}
.el-divider--horizontal {
  margin: 0;
}
/deep/ .el-input.el-input--medium.el-input--suffix {
  line-height: 50px !important;
}
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #999;
  font-size: 14px;
}
</style>
